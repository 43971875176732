/** Config EmptyShelves */
export const apiBase = 'https://api.palletiq.com/v1/';
export const loginPageEmail = 'support@palletiq.com';
export const loginPageLogo = 'PIQ/logo_login.png';
export const loginPageBanner = 'PIQ/login_banner.png';
export const homePageLogo = 'PIQ/logo_home.png';
export const favicon = 'PIQ/favicon.ico';
export const documentTitle = 'PALLETIQ';
export const logoWidth = 150;
export const socketIOServerAdd = 'https://api.palletiq.com';

//** To make it available for all the users, leave forTeams=[] blank */
export const winnersMap = [
  {
    conditions: {
      module: '',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'REJECT',
      bgColor: '#F20050',
      textColor: '#FFFFFF',
      sound: 'audioReject',
    },
  },
  {
    conditions: {
      module: 'MARKETPLACE',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'MARKETPLACE',
      bgColor: '#D1859A',
      textColor: '#FFFFFF',
      sound: 'audioMP',
    },
  },
  {
    conditions: {
      module: 'MARKETPLACE MF',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'MARKETPLACE MF',
      bgColor: '#537BE4',
      textColor: '#FFFFFF',
      sound: 'audioMP',
    },
  },
  {
    conditions: {
      module: 'AMAZON',
      subModule: 'FBA',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'FBA',
      bgColor: '#00743f',
      textColor: '#FFFFFF',
      sound: 'audioFBA',
    },
  },
  {
    conditions: {
      module: 'AMAZON',
      subModule: 'MF',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'MF',
      bgColor: '#F4874B',
      textColor: '#FFFFFF',
      sound: 'audioMF',
    },
  },
  {
    conditions: {
      module: 'AMAZON CA',
      subModule: 'FBA',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'FBA CA',
      bgColor: '#00743f',
      textColor: '#FFFFFF',
      sound: 'audioFBACA',
    },
  },
  {
    conditions: {
      module: 'AMAZON CA',
      subModule: 'MF',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'MF CA',
      bgColor: '#F4874B',
      textColor: '#FFFFFF',
      sound: 'audioMFCA',
    },
  },
  {
    conditions: {
      module: 'MARKETPLACE CA',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'MARKETPLACE CANADA',
      bgColor: '#8586D1',
      textColor: '#FFFFFF',
      sound: 'audioMPCA',
    },
  },
  {
    conditions: {
      module: 'AMAZON NEW',
      subModule: 'FBA',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'FBA-NEW',
      bgColor: '#00743f',
      textColor: '#FFFFFF',
      sound: 'audioFBANew',
    },
  },
  {
    conditions: {
      module: 'AMAZON NEW',
      subModule: 'MF',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'MF-NEW',
      bgColor: '#F4874B',
      textColor: '#FFFFFF',
      sound: 'audioMFNew',
    },
  },
  {
    conditions: {
      module: 'WHOLESALE',
      subModule: 'SBYB',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'SBYB',
      bgColor: '#6a8a82',
      textColor: '#FFFFFF',
      sound: 'audioSBYB',
    },
  },
  {
    conditions: {
      module: 'WHOLESALE',
      subModule: 'RMS',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'RMS',
      bgColor: '#fbbc04',
      textColor: '#FFFFFF',
      sound: 'audioRMS',
    },
  },
  {
    conditions: {
      module: 'WHOLESALE',
      subModule: 'SB',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'RMS',
      bgColor: '#fbbc04',
      textColor: '#FFFFFF',
      sound: 'audioRMS',
    },
  },
  {
    conditions: {
      module: 'WHOLESALE',
      subModule: 'BR',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'BOOKSRUN',
      bgColor: '#0D3C55',
      textColor: '#FFFFFF',
      sound: 'audioBR',
    },
  },
  {
    conditions: {
      module: 'WHOLESALE',
      subModule: 'BRB',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'BLUE ROCKET BOOKS',
      bgColor: '#212323',
      textColor: '#FFFFFF',
      sound: 'audioBRB',
    },
  },

  {
    conditions: {
      module: 'WHOLESALE',
      subModule: 'ZIFFIT',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'ZIFFIT',
      bgColor: '#0444bf',
      textColor: '#FFFFFF',
      sound: 'audioZIFFIT',
    },
  },
  {
    conditions: {
      module: 'WHOLESALE',
      subModule: 'UBB',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'UBB',
      bgColor: '#474749',
      textColor: '#FFFFFF',
      sound: 'audioUBB',
    },
  },
  {
    conditions: {
      module: 'BOOKSCOUTER',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: 'BS: [SUB_MODULE]',
      bgColor: '#a37c27',
      textColor: '#FFFFFF',
      sound: 'audioBS',
    },
  },
  {
    conditions: {
      module: 'BOOKSTORE',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: '[SUB_MODULE]',
      bgColor: '#6465A5',
      textColor: '#FFFFFF',
      sound: 'audioWS',
    },
  },
  {
    conditions: {
      module: 'TEXTBOOK',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: '[SUB_MODULE]',
      bgColor: '#6465A5',
      textColor: '#FFFFFF',
      sound: 'audioTB',
    },
  },
  {
    conditions: {
      module: 'HITLIST',
      subModule: '',
      forTeams: [],
      excludeTeams: [],
    },
    display: {
      name: '[SUB_MODULE]',
      bgColor: '#040c0e',
      textColor: '#FFFFFF',
      sound: 'audioHitList',
    },
  },
];
