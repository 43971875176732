import React, { Component } from "react";
import { Modal, Form, Spinner, Button } from "react-bootstrap";

class ModalListingProfile extends Component {
  state = {
    apiInProgress: false,
  };

  onFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    return (
      <Modal show={this.props.showNewLPModal} onHide={this.props.handleClose}>
        <Form onSubmit={this.onFormSubmit}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="listingProfileName"
              className="input-text-lp-name"
              placeholder="Enter Listing Profile Name Here"
            />
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            {this.state.apiInProgress === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalListingProfile;
