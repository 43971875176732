import React, { Component } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import "../assets/style/login.css";
import { submitForgotPassword } from "../includes/HelperLogin";
import { loginPageBanner, loginPageEmail, loginPageLogo, logoWidth } from "../Config";
// import auth from "../Auth";

class ForgotPassword extends Component {
  state = {
    logingin: false,
    isInvalidLogin: -1,
    invalidLoginMessage: "",
    isTokenExpired: 0,
    loginPageLogo: loginPageLogo,
    successMessage: "",
  };

  constructor() {
    super();
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let isTokenExpired = params.get("token-expiry");
    if (isTokenExpired !== null) this.setState({ isTokenExpired: parseInt(isTokenExpired) });
  }

  async handleForgotPassword(event) {
    event.preventDefault();
    const user_email = event.target.elements.user_email.value;

    this.setState({ logingin: true });
    const response = await submitForgotPassword(
      user_email,
      this.props.history.location.state?.isWLSite ? this.props.history.location.state.wlTeamId : 0
    );
    this.setState({ logingin: false });
    if (response.status === false) {
      this.setState({ isInvalidLogin: 1, invalidLoginMessage: response.error });
    } else {
      this.setState({ successMessage: "We have sent a password recover instructions to your email" });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
          <Col md={5} className="div-login-left">
            <div className="div-form-container">
              <div>
                <img
                  alt="logo"
                  className="logo"
                  style={{ width: `${logoWidth}px` }}
                  src={
                    !this.props.history.location.state?.isWLSite
                      ? require(`../assets/images/${this.state.loginPageLogo}`)
                      : `https://files.palletiq.com/site_identities/${this.props.history.location.state.loginPageLogo}`
                  }
                />
              </div>

              {this.state.isInvalidLogin === 1 && (
                <div style={{ marginTop: "20px" }}>
                  <Alert variant="danger">
                    <span>{this.state.invalidLoginMessage}</span>
                  </Alert>
                </div>
              )}
              <div>
                {this.state.isTokenExpired === 1 && (
                  <Alert style={{ marginTop: "40px" }} key="auto-logout" variant="warning">
                    You have been logged out because you logged in from another device.
                  </Alert>
                )}

                <div className="welcome-msg-login">
                  Enter the email associated with your account and we'll send an email with instructions to reset your
                  password
                </div>

                {this.state.successMessage !== "" && (
                  <div style={{ color: "#28A745", marginBottom: "20px" }}>{this.state.successMessage}</div>
                )}

                <Form onSubmit={this.handleForgotPassword}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label style={{ marginBottom: "0px" }}>Email address</Form.Label>
                    <Form.Control autoFocus type="email" name="user_email" className="inputNoFormat" />
                  </Form.Group>

                  {this.state.logingin ? (
                    <Spinner style={{ marginTop: "33px" }} animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      variant="info"
                      type="submit"
                      style={{ marginTop: "30px", borderRadius: "0", padding: "7px 25px 7px 25px" }}
                    >
                      Send Instructions
                    </Button>
                  )}
                </Form>

                <div className="support-msg" style={{ marginTop: "20px" }}>
                  <Button
                    onClick={() => {
                      this.props.history.push("/");
                    }}
                    style={{ padding: "0" }}
                    variant="link"
                  >
                    Click here to Login
                  </Button>
                </div>

                <div className="support-msg">
                  For any questions, please email us at{" "}
                  {!this.props.history.location.state?.isWLSite
                    ? loginPageEmail
                    : this.props.history.location.state.loginPageEmail}
                </div>

                <div className="support-msg" style={{ marginTop: "50px" }}>
                  <Button
                    onClick={() => {
                      this.props.history.push("privacy-policy");
                    }}
                    style={{ padding: "0", color: "#353434" }}
                    variant="link"
                  >
                    Privacy Policy
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col
            md={7}
            style={{
              background: !this.props.history.location.state?.isWLSite
                ? `url(${require("../assets/images/" + loginPageBanner)}) no-repeat center center`
                : `url(https://files.palletiq.com/site_identities/${this.props.history.location.state.loginPageBanner}) no-repeat center center`,
              backgroundSize: "cover",
              backgroundPosition: "left 0px top 0px",
            }}
          ></Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
