import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";

class Step1Email extends Component {
  state = {
    errorMessage: "",
  };

  render() {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>Trigger Setup</h2>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p style={{ fontSize: "18px" }}>
            If FBA and MF have the same expected profit, which option would you select to be the winner?
          </p>
          <Form.Check
            inline
            label="FBA"
            type="radio"
            name="fba-vs-mf"
            id="fba-vs-mf-1"
            checked={this.props.inputVal === "fba"}
            onChange={(e) => {
              this.props.setFBAvsMF("fba");
            }}
          />
          <Form.Check
            style={{ marginLeft: "25px" }}
            inline
            label="MF"
            type="radio"
            name="fba-vs-mf"
            id="fba-vs-mf-2"
            checked={this.props.inputVal === "mf"}
            onChange={(e) => {
              this.props.setFBAvsMF("mf");
            }}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ margin: "20px 20px 0 0" }}
              variant="primary"
              onClick={() => {
                this.setState({ errorMessage: "" });
                this.props.gotoPreviousScreen();
              }}
            >
              Previous
            </Button>
            <Button
              style={{ marginTop: "20px" }}
              variant="primary"
              onClick={() => {
                if (this.props.inputVal === "" || this.props.inputVal === null) {
                  this.setState({ errorMessage: "Input Required" });
                } else {
                  this.setState({ errorMessage: "" });
                  this.props.gotoNextScreen();
                }
              }}
            >
              Next
            </Button>
          </div>

          {this.state.errorMessage !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Step1Email;
