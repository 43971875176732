import React, { Component } from "react";
import { documentTitle } from "../Config";

class InfoBoxes extends Component {
  state = {};

  constructor() {
    super();
    this.updateWinnerAndRedirect = this.updateWinnerAndRedirect.bind(this);
  }

  addZeroes(amount) {
    if (amount === "N/A" || amount === undefined) return amount;
    else {
      let num = Number(amount);
      if (isNaN(num)) return 0;
      if (String(num).split(".").length < 2 || String(num).split(".")[1].length <= 2) num = num.toFixed(2);
      return num;
    }
  }
  sort_by_key(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  async updateWinnerAndRedirect(asin, bsPrice) {
    this.props.logBSClick(bsPrice);
    await this.props.updateStaticWinner(bsPrice.vendor);
    this.props.handleBSDoubleClick(
      `https://referral.bookscouter.com/click.track?CID=429021&AFID=464468&type=sell&isbn=${asin}&exitToVendorId=${bsPrice.id}`
    );
  }
  // Hello
  render() {
    return (
      <div className="row-infoBoxes border_bottom_infobox">
        <div className="infoBoxes-container">
          {this.props.infoBoxesData
            // .filter((ibdf) => {
            //   console.log(`Checking ${ibdf.name}`);
            //   if (
            //     (ibdf.name === "FBA" || ibdf.name === "MF") &&
            //     this.props.infoBoxesData.findIndex((ibd) => ibd.name === "MP") >= 0
            //   )
            //     return false;
            //   else return true;
            // })
            //https://www.ziffit.com/en-us/cart?eans=9780525536291,9780525536291&scanOrigin=ABC&cartId=ab12
            .map((ib, index_ib) => {
              const userSettings = JSON.parse(this.props.user_data.settings);

              if (
                documentTitle !== "EMPTY SHELVES" ||
                (documentTitle === "EMPTY SHELVES" &&
                  this.props.user_data.is_mm !== 1 &&
                  this.props.user_data.infoboxes_enabled === 1) ||
                (documentTitle === "EMPTY SHELVES" && this.props.user_data.is_mm === 1 && ib.name === "MP")
              )
                return (
                  <div
                    key={"ib_" + index_ib}
                    className={ib.price > 0 ? "infoBoxes" : "infoBoxesNull"}
                    onClick={() => this.props.updateStaticWinner(ib.name)}
                  >
                    <div className="infoHeading">
                      {documentTitle === "EMPTY SHELVES" && this.props.user_data.is_mm === 1 && ib.name === "MP"
                        ? "Offer"
                        : ib.name}
                    </div>

                    <div
                      className={
                        "infoValue " +
                        (this.props.user_data.hidden_mode === 1 &&
                        documentTitle !== "EMPTY SHELVES" &&
                        this.props.user_data.is_mm !== 1
                          ? "visHid"
                          : "")
                      }
                      style={{ marginBottom: "3px" }}
                    >
                      {ib.price == -404 ? "Error" : ib.price > 0 ? this.addZeroes(ib.price) : "0"}
                    </div>
                    <div
                      className={
                        (ib.name === "FBA" || ib.name === "MF" || ib.name === "FBA-NEW" || ib.name === "MF-NEW") &&
                        this.props.isRestricted === 1 &&
                        "onRestrictedSkipAmazon" in userSettings &&
                        userSettings.onRestrictedSkipAmazon === "yes"
                          ? "amz_no"
                          : ib.isAccepted === true
                          ? "amz_yes"
                          : "amz_no"
                      }
                    ></div>
                  </div>
                );
              else return <span></span>;
            })}

          {this.props.bsPrices.length > 0 && (
            <div className="bs-prices-container">
              <div>
                <div style={{ display: "inline-block", padding: "9px 6px", paddingBottom: "2px" }}>
                  <div style={{ color: "#CCCCCC", fontSize: "14px", fontWeight: "bold" }}>BS</div>
                  <div style={{ fontSize: "18px", color: "#ffffff" }}>offers</div>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    background: "#fff",
                  }}
                >
                  {this.props.bsPrices.map((bsPrice) => {
                    return (
                      <div
                        className="bs-price-ib"
                        onClick={async () => await this.updateWinnerAndRedirect(this.props.asin, bsPrice)}
                        // onDoubleClick={() =>
                        //   this.props.handleBSDoubleClick(
                        //     `https://referral.bookscouter.com/click.track?CID=429021&AFID=464468&type=sell&isbn=${this.props.asin}&exitToVendorId=${bsPrice.id}`
                        //   )
                        // }
                      >
                        <div style={{ fontWeight: "bold", color: "#47525d", fontSize: "14px" }}>
                          {bsPrice.vendor.toUpperCase()}
                        </div>
                        <div style={{ color: "#2D2D2D", fontSize: "18px" }}>{this.addZeroes(bsPrice.price)}</div>
                        <div className={bsPrice.price > 0 ? "amz_yes" : "amz_no"}></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InfoBoxes;
