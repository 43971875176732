import React, { Component } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import rejectImage from "../assets/images/reject.png";
import { documentTitle } from "../Config";
import { apiBase } from "../Config";

class AsinTitle extends Component {
  state = {};

  //New Function 2
  render() {
    return (
      <div className="asin-title-container">
        <div className="asin-title-image-container">
          <img
            alt={this.props.asinMeta.asin}
            style={{ minWidth: "85px", maxWidth: "85px" }}
            src={
              "image_url" in this.props.asinMeta
                ? this.props.asinMeta.image_url.replace("SL75", "SL125")
                : `${apiBase}amazon/image/${this.props.asinMeta.asin}`
            }
          />
        </div>
        <div className="asin-title-main-container">
          <div
            className={
              this.props.isValidBuy === 1
                ? "titleAndDecision acceptItem"
                : this.props.isValidBuy === 0
                ? "titleAndDecision rejectItem"
                : "titleAndDecision pendingDecision"
            }
            style={{
              background:
                this.props.moduleWinnerColor !== "" ? this.props.moduleWinnerColor : this.props.winnerDisplay.bgColor,
              color: this.props.winnerDisplay.textColor,
            }}
          >
            <div className="validbuysource">
              {/* {this.props.isRestricted === 1 ? (
                <span class="dot-disconnected"></span>
              ) : this.props.isRestricted === 0 ? (
                <span class="dot-connected"></span>
              ) : (
                <span></span>
              )} */}
              {this.props.updatingWinner === true ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : this.props.isValidBuy === 1 ? (
                <span>
                  {this.props.moduleWinnerText !== ""
                    ? this.props.moduleWinnerText
                    : this.props.winnerDisplay.name.indexOf("[SUB_MODULE]") >= 0
                    ? this.props.winnerDisplay.name.replace("[SUB_MODULE]", this.props.subModuleWinner)
                    : this.props.winnerDisplay.name}
                </span>
              ) : this.props.isValidBuy === 0 ? (
                <span>
                  <img style={{ width: "34px" }} alt="X" src={rejectImage} /> <span>REJECT</span>
                </span>
              ) : (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </div>
          </div>
          <div className="titleAndDecision container-cats">
            <div>{this.props.title.length > 50 ? this.props.title.substr(0, 50 - 1) + " ..." : this.props.title}</div>
            <Row style={{ marginTop: "20px" }}>
              <Col md={6} className="dalignleft-maligncenter" style={{ marginBottom: "10px" }}>
                {documentTitle === "PALLETIQ" && (
                  <span style={{ marginRight: "24px" }}>
                    <i
                      onClick={() => this.props.printISBN(this.props.asinMeta.asin)}
                      title="Print Label"
                      className={"fa  fa-print"}
                      style={{ cursor: "pointer", color: "blanchedalmond" }}
                    />
                  </span>
                )}

                <span className="categoryNames" style={{ color: "var(--l2tertiary)" }}>
                  <i
                    className={
                      "fa " +
                      (this.props.asinMeta.product_group === "Book"
                        ? "fa-book"
                        : this.props.asinMeta.product_group.indexOf("DVD") >= 0
                        ? "fa-video-camera"
                        : this.props.asinMeta.product_group.indexOf("CD") >= 0 ||
                          this.props.asinMeta.product_group.indexOf("Music") >= 0
                        ? "fa-music"
                        : " fa-shopping-cart")
                    }
                    style={{ marginRight: "5px" }}
                  />
                  {this.props.asinMeta.product_group}
                </span>
                <span className="categoryNames">
                  ASIN:{" "}
                  {documentTitle === "BOOKTERIORS" || documentTitle === "EMPTY SHELVES" ? (
                    <span>{this.props.asinMeta.asin}</span>
                  ) : (
                    <a
                      className="plainHyperLinks"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://www.amazon.com/gp/offer-listing/" + this.props.asinMeta.asin}
                    >
                      {this.props.asinMeta.asin}
                    </a>
                  )}
                </span>
              </Col>
              {this.props.categories !== undefined && this.props.categories.length > 0 ? (
                <Col md={6} className="book-info-cats" style={{ marginBottom: "10px" }}>
                  {this.props.categories.map((category, _index) => {
                    return (
                      <span
                        key={"cat_" + _index}
                        className={"categoryNames" + (this.props.categories.length === 1 ? " no-right-margin" : "")}
                      >
                        {category === "" ? "" : category}
                      </span>
                    );
                  })}
                </Col>
              ) : (
                <Col className="book-info-cats" md={7} style={{ marginBottom: "10px" }}>
                  <span className={"categoryNames"}>...</span>
                </Col>
              )}
            </Row>

            <div className="meta-info"></div>
          </div>
        </div>
      </div>

      // <Row>
      //   <Col md={12}>
      //     {this.props.invalidAsin ? (
      //       <div className="titleAndDecisionInvalid">Invalid ASIN</div>
      //     ) : (
      //       <div style={{ width: "980px", margin: "0 auto", maxWidth: "100%" }}>
      //         <div className="decisionImageContainer">
      //           <div style={{ lineHeight: "150px" }}>
      //             <img
      //               alt={this.props.asinMeta.asin}
      //               style={{ marginRight: "10px", maxWidth: "85px" }}
      //               src={
      //                 "image_url" in this.props.asinMeta
      //                   ? this.props.asinMeta.image_url.replace("SL75", "SL125")
      //                   : `${apiBase}amazon/image/${this.props.asinMeta.asin}`
      //               }
      //             />
      //           </div>
      //         </div>

      //         <div>
      //           <div
      //             className={
      //               this.props.isValidBuy === 1
      //                 ? "titleAndDecision acceptItem"
      //                 : this.props.isValidBuy === 0
      //                 ? "titleAndDecision rejectItem"
      //                 : "titleAndDecision pendingDecision"
      //             }
      //             style={{
      //               background:
      //                 this.props.moduleWinnerColor !== ""
      //                   ? this.props.moduleWinnerColor
      //                   : this.props.winnerDisplay.bgColor,
      //               color: this.props.winnerDisplay.textColor,
      //             }}
      //           >
      //             <div className="validbuysource">
      //               {this.props.isRestricted === 1 ? (
      //                 <span class="dot-disconnected"></span>
      //               ) : this.props.isRestricted === 0 ? (
      //                 <span class="dot-connected"></span>
      //               ) : (
      //                 <span></span>
      //               )}
      //               {this.props.updatingWinner === true ? (
      //                 <Spinner animation="border" role="status">
      //                   <span className="sr-only">Loading...</span>
      //                 </Spinner>
      //               ) : this.props.isValidBuy === 1 ? (
      //                 <span>
      //                   {this.props.moduleWinnerText !== ""
      //                     ? this.props.moduleWinnerText
      //                     : this.props.winnerDisplay.name === "[SUB_MODULE]"
      //                     ? this.props.subModuleWinner
      //                     : this.props.winnerDisplay.name}
      //                 </span>
      //               ) : this.props.isValidBuy === 0 ? (
      //                 <span>
      //                   <img style={{ width: "34px" }} alt="X" src={rejectImage} /> <span>REJECT</span>
      //                 </span>
      //               ) : (
      //                 <Spinner animation="border" role="status">
      //                   <span className="sr-only">Loading...</span>
      //                 </Spinner>
      //               )}
      //             </div>
      //           </div>
      //           <div className="titleAndDecision container-cats">
      //             <div>
      //               {this.props.title.length > 50 ? this.props.title.substr(0, 50 - 1) + " ..." : this.props.title}
      //             </div>
      //             <Row style={{ marginTop: "20px" }}>
      //               <Col md={5} className="dalignleft-maligncenter" style={{ marginBottom: "10px" }}>
      //                 <i
      //                   onClick={() => this.props.printISBN(this.props.asinMeta.asin)}
      //                   title="Print Label"
      //                   className={"fa  fa-print"}
      //                   style={{ cursor: "pointer", color: "blanchedalmond" }}
      //                 />

      //                 <span className="categoryNames" style={{ color: "var(--l2tertiary)", marginLeft: "24px" }}>
      //                   <i
      //                     className={
      //                       "fa " +
      //                       (this.props.asinMeta.product_group === "Book"
      //                         ? "fa-book"
      //                         : this.props.asinMeta.product_group.indexOf("DVD") >= 0
      //                         ? "fa-video-camera"
      //                         : this.props.asinMeta.product_group.indexOf("CD") >= 0 ||
      //                           this.props.asinMeta.product_group.indexOf("Music") >= 0
      //                         ? "fa-music"
      //                         : " fa-shopping-cart")
      //                     }
      //                     style={{ marginRight: "5px" }}
      //                   />
      //                   {this.props.asinMeta.product_group}
      //                 </span>
      //                 <span className="categoryNames">
      //                   ASIN:{" "}
      //                   <a
      //                     className="plainHyperLinks"
      //                     target="_blank"
      //                     rel="noopener noreferrer"
      //                     href={"https://www.amazon.com/gp/offer-listing/" + this.props.asinMeta.asin}
      //                   >
      //                     {this.props.asinMeta.asin}
      //                   </a>
      //                 </span>
      //               </Col>
      //               {this.props.categories !== undefined && this.props.categories.length > 0 ? (
      //                 <Col md={7} className="book-info-cats" style={{ marginBottom: "10px" }}>
      //                   {this.props.categories.map((category, _index) => {
      //                     return (
      //                       <span
      //                         key={"cat_" + _index}
      //                         className={
      //                           "categoryNames" + (this.props.categories.length === 1 ? " no-right-margin" : "")
      //                         }
      //                       >
      //                         {category === "" ? "" : category}
      //                       </span>
      //                     );
      //                   })}
      //                 </Col>
      //               ) : (
      //                 <Col className="book-info-cats" md={7} style={{ marginBottom: "10px" }}>
      //                   <span className={"categoryNames"}>...</span>
      //                 </Col>
      //               )}
      //             </Row>

      //             <div className="meta-info"></div>
      //           </div>
      //         </div>
      //       </div>
      //     )}
      //   </Col>
      // </Row>
    );
  }
}

export default AsinTitle;
