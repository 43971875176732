import React, { Component } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import "../assets/style/login.css";
import { submitResetPassword } from "../includes/HelperLogin";
import { validateInviteToken } from "../includes/HelperSignup";
import { apiBase, loginPageBanner, loginPageEmail, loginPageLogo, logoWidth } from "../Config";
// import auth from "../Auth";

class ResetPassword extends Component {
  state = {
    logingin: false,
    isInvalidLogin: -1,
    isSuccess: false,
    invalidLoginMessage: "",
    isTokenExpired: 0,
    isWLSite: false,
    isWLReady: false,
    loginPageLogo: loginPageLogo,
    successMessage: "",
    loginPageEmail: "",
    validatingToken: true,
  };

  constructor() {
    super();
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.getSiteIdentity = this.getSiteIdentity.bind(this);
  }

  async getSiteIdentity(subDomain) {
    console.log(`Reading WL for ${subDomain}`);
    const response = await fetch(apiBase + `admin/whitelabel/site/${subDomain}`);
    const wlData = await response.json();
    if (wlData.status) {
      this.setState({
        wlTeamId: wlData.data.id,
        loginPageLogo: wlData.data.image_path_logo_1,
        loginPageBanner: wlData.data.image_path_home_banner,
        loginPageEmail: wlData.data.support_email,
      });
      document.title = wlData.data.browser_title;
    }

    this.setState({ isWLReady: true });
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let appToken = params.get("token");

    this.setState({ appToken, isSuccess: false });

    // Call and see if home banner and logos are there for the team
    if (window.location.hostname.includes("scanporter.com") && window.location.hostname.split(".").length === 3) {
      console.log(`------------------\nScanporter code\n--------------\n\n`);
      const teamIdentifier = window.location.hostname.split(".")[0];
      // Check to see which team
      this.setState({ isWLSite: true });
      this.getSiteIdentity(teamIdentifier);
    }

    this.validateToken(appToken);
  }

  async validateToken(appToken) {
    // Verify Token
    this.setState({ validatingToken: true });
    const response = await validateInviteToken(appToken);
    if (undefined !== response && "status" in response && response.status === true) {
      this.setState({
        invalidToken: false,
      });
    } else {
      this.setState({ invalidToken: true });
    }
    this.setState({ validatingToken: false });
    console.log(response.status);
  }

  async handleResetPassword(event) {
    event.preventDefault();
    const user_password = event.target.elements.user_password.value;
    const user_password_2 = event.target.elements.user_password_2.value;

    if (user_password !== user_password_2) {
      this.setState({ isInvalidLogin: 1, invalidLoginMessage: "Passwords do not match" });
    } else {
      this.setState({ logingin: true });
      const response = await submitResetPassword(this.state.appToken, user_password_2);
      this.setState({ logingin: false });
      if (response.status === false) {
        this.setState({ isInvalidLogin: 1, invalidLoginMessage: response.error });
      } else {
        this.setState({
          isSuccess: true,
          successMessage: "We have sent a password recover instructions to your email",
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.validatingToken === true ? (
          <div style={{ marginTop: "100px", textAlign: "center" }}>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : this.state.validatingToken === false && this.state.invalidToken === false ? (
          <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
            <Col md={5} className="div-login-left">
              <div className="div-form-container">
                <div>
                  <img
                    alt="logo"
                    className="logo"
                    style={{ width: `${logoWidth}px` }}
                    src={
                      !this.state.isWLSite
                        ? require(`../assets/images/${this.state.loginPageLogo}`)
                        : `https://files.palletiq.com/site_identities/${this.state.loginPageLogo}`
                    }
                  />
                </div>

                {this.state.isInvalidLogin === 1 && (
                  <div style={{ marginTop: "20px" }}>
                    <Alert variant="danger">
                      <span>{this.state.invalidLoginMessage}</span>
                    </Alert>
                  </div>
                )}
                <div>
                  <div className="welcome-msg-login">
                    <h4>Create New Password</h4>
                  </div>

                  {this.state.isSuccess && (
                    <div style={{ color: "#28A745", marginBottom: "20px" }}>
                      <Button
                        onClick={() => {
                          this.props.history.push("/");
                        }}
                        style={{ padding: "0", color: "green" }}
                        variant="link"
                      >
                        Password Reset Successful! Click here to login
                      </Button>
                    </div>
                  )}

                  <Form onSubmit={this.handleResetPassword}>
                    <Form.Group style={{ marginTop: "50px" }} controlId="formBasicPassword">
                      <Form.Label style={{ marginBottom: "0px" }}>Enter Password</Form.Label>
                      <Form.Control type="password" name="user_password" className="inputNoFormat" />
                    </Form.Group>

                    <Form.Group style={{ marginTop: "50px" }} controlId="formBasicPassword">
                      <Form.Label style={{ marginBottom: "0px" }}>Confirm Password</Form.Label>
                      <Form.Control type="password" name="user_password_2" className="inputNoFormat" />
                    </Form.Group>

                    {this.state.logingin ? (
                      <Spinner style={{ marginTop: "33px" }} animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ marginTop: "30px", borderRadius: "0", padding: "7px 25px 7px 25px" }}
                      >
                        Change Password
                      </Button>
                    )}
                  </Form>

                  <div className="support-msg">
                    For any questions, please email us at{" "}
                    {!this.state.isWLSite ? loginPageEmail : this.state.loginPageEmail}
                  </div>

                  <div className="support-msg" style={{ marginTop: "50px" }}>
                    <Button
                      onClick={() => {
                        this.props.history.push("privacy-policy");
                      }}
                      style={{ padding: "0", color: "#353434" }}
                      variant="link"
                    >
                      Privacy Policy
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              md={7}
              style={{
                background: !this.state.isWLSite
                  ? `url(${require("../assets/images/" + loginPageBanner)}) no-repeat center center`
                  : `url(https://files.palletiq.com/site_identities/${this.state.loginPageBanner}) no-repeat center center`,
                backgroundSize: "cover",
                backgroundPosition: "left 0px top 0px",
              }}
            ></Col>
          </Row>
        ) : (
          <div style={{ color: "red", marginTop: "50px", textAlign: "center" }}>Invalid Token</div>
        )}
      </React.Fragment>
    );
  }
}

export default ResetPassword;
