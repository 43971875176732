import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";

class Step1Email extends Component {
  state = {
    errorMessage: "",
  };

  gotoNextStep() {
    if (this.props.inputVal === "" || this.props.inputVal === null) {
      this.setState({ errorMessage: "Input Required" });
    } else {
      this.setState({ errorMessage: "" });
      this.props.gotoNextScreen();
    }
  }

  // Check if None is selected
  render() {
    return (
      <div
        onKeyPress={(target) => {
          if (target.charCode === 13) this.gotoNextStep();
        }}
      >
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>{this.props.heading}</h2>
        </div>

        <div style={{ marginTop: "30px", lineHeight: "2.25em" }}>
          <p style={{ fontSize: "18px" }}>{this.props.message}</p>
          {this.props.smallMessage !== "" && (
            <div style={{ margin: "5px 0 15px 0" }}>
              <i>{this.props.smallMessage}</i>
            </div>
          )}

          {this.props.options.map((option, index) => {
            return (
              <Form.Check
                style={{ marginLeft: "20px" }}
                inline
                key={"opt_" + option + "_" + index}
                label={option}
                value={option}
                disabled={this.props.checkedList.findIndex((cl) => cl === "None") >= 0 && option !== "None"}
                type="checkbox"
                id={`option-${index}`}
                checked={this.props.checkedList.indexOf(option) > -1 ? true : false}
                onChange={(e) => this.props.exportUpdatedList(e)}
              />
            );
          })}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ margin: "20px 20px 0 0" }}
              variant="primary"
              onClick={() => {
                this.setState({ errorMessage: "" });
                this.props.gotoPreviousScreen();
              }}
            >
              Previous
            </Button>
            <Button style={{ marginTop: "20px" }} variant="primary" onClick={() => this.gotoNextStep()}>
              Next
            </Button>
          </div>

          {this.state.errorMessage !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Step1Email;
