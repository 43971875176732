import React, { Component } from "react";
import { Button, Form, Spinner } from "react-bootstrap";

class StepPassword extends Component {
  state = {
    errorMessage: "",
  };
  gotoNextStep() {
    if (this.props.userPassword === "" || this.props.userPassword2 === "") {
      this.setState({ errorMessage: "Input Required" });
    } else if (this.props.userPassword !== this.props.userPassword2) {
      this.setState({ errorMessage: `Passwords do not match` });
    } else {
      this.setState({ errorMessage: "" });
      this.props.gotoNextScreen();
    }
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>Finishing signup ...</h2>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p style={{ fontSize: "20px" }}>Finally, choose a password for your admin account</p>
          {this.props.smallMessage !== "" && (
            <div style={{ margin: "5px 0 15px 0" }}>
              <i>{this.props.smallMessage}</i>
            </div>
          )}

          <div>
            <table style={{ margin: "0 auto", borderWidth: "1px", borderColor: "#aaaaaa", borderStyle: "solid" }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", padding: "20px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>Enter your password</div>
                  </td>
                  <td>
                    <Form.Control
                      onChange={(e) => {
                        this.props.exportPassword(e.target.value);
                      }}
                      onKeyPress={(target) => {
                        if (target.charCode === 13) this.gotoNextStep();
                      }}
                      ref={(input) => {
                        this.nameInput = input;
                      }}
                      value={this.props.userPassword}
                      style={{ width: "450px", maxWidth: "100%" }}
                      autoComplete="off"
                      type="password"
                      className="onboardingInputs"
                      aria-describedby="basic-addon1"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center", padding: "20px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>Confirm your password</div>
                  </td>
                  <td style={{ padding: "20px" }}>
                    <Form.Control
                      onChange={(e) => {
                        this.props.exportPassword2(e.target.value);
                      }}
                      onKeyPress={(target) => {
                        if (target.charCode === 13) this.gotoNextStep();
                      }}
                      value={this.props.userPassword2}
                      style={{ width: "450px", maxWidth: "100%" }}
                      autoComplete="off"
                      type="password"
                      className="onboardingInputs"
                      aria-describedby="basic-addon1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ margin: "20px 20px 0 0" }}
              variant="primary"
              onClick={() => {
                this.setState({ errorMessage: "" });
                this.props.gotoPreviousScreen();
              }}
            >
              Previous
            </Button>

            <Button style={{ marginTop: "20px" }} variant="primary" onClick={() => this.gotoNextStep()}>
              {this.props.signupInProgres === true ? (
                <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Finish"
              )}
            </Button>
          </div>
          {this.state.errorMessage !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StepPassword;
