import { apiBase } from "../Config";

export const submitSignup = async (signup_json) => {
  const response = await fetch(apiBase + "user/signup", {
    method: "POST",
    body: new URLSearchParams({
      signup_json: signup_json,
    }),
  });
  const data = await response.json();
  return data;
};

export const validateMWS = async (seller_id, mws_auth) => {
  const response = await fetch(apiBase + `user/validate-mws?seller_id=${seller_id}&mws_auth=${mws_auth}`);
  const data = await response.json();
  return data;
};

export const validateInviteToken = async (token) => {
  const response = await fetch(apiBase + `user/validate-invite-token?token=${token}`);
  const data = await response.json();
  return data;
};
