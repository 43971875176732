import React, { Component } from "react";
import { Button } from "react-bootstrap";

class Step1Email extends Component {
  constructor(props) {
    super(props);
    this.enterKeyFunction = this.enterKeyFunction.bind(this);
  }

  enterKeyFunction(event) {
    if (event.keyCode === 13) this.props.gotoNextScreen();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.enterKeyFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.enterKeyFunction, false);
  }

  render() {
    return (
      <div
        onKeyPress={(target) => {
          if (target.charCode === 13) this.props.gotoNextScreen();
        }}
      >
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>{this.props.infoMessage}</h2>
          <h5 style={{ fontWeight: 100 }}>{this.props.infoMessageSmall}</h5>
        </div>

        <div style={{ marginTop: "50px" }}>
          {"smallMessageElement" in this.props && this.props.smallMessageElement}
          {this.props.lastStep !== true && (
            <div>
              <Button
                style={{ margin: "20px 20px 0 0" }}
                variant="primary"
                onClick={() => {
                  this.props.gotoPreviousScreen();
                }}
              >
                Previous
              </Button>
              <Button
                style={{ marginTop: "20px" }}
                variant="primary"
                onClick={() => {
                  this.props.gotoNextScreen();
                }}
              >
                Continue
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Step1Email;
