import React, { Component } from "react";
import { Navbar, Nav, Form, Badge } from "react-bootstrap";
import { homePageLogo, logoWidth, documentTitle } from "../Config";
import auth from "../Auth";
import ModalListingProfile from "../components/ModalListingProfile";

class TopMenu extends Component {
  state = {
    homePageLogo: homePageLogo,
    showNewLPModal: false,
  };

  handleLogout = () => {
    auth.logout(() => {
      this.props.history.push("/");
    });
  };

  render() {
    let domainName = window.location.hostname;
    if (documentTitle === "EMPTY SHELVES") domainName = "app.emptyshelves.com";

    return (
      <Navbar bg="dark" expand="lg" variant="dark">
        <ModalListingProfile
          showNewLPModal={this.state.showNewLPModal}
          handleClose={() => this.setState({ showNewLPModal: false })}
        />

        <Navbar.Brand href="#home">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img
                style={{ width: `${logoWidth}px` }}
                alt="logo"
                className="logo-home"
                src={
                  !this.props.isWLSite
                    ? require(`../assets/images/${this.state.homePageLogo}`)
                    : `https://files.palletiq.com/site_identities/${this.props.logoWL}`
                }
              />
            </div>

            {documentTitle !== "EMPTY SHELVES" &&
            documentTitle !== "BOOKTERIORS" &&
            documentTitle !== "BLUEINKBOOKS" &&
            "logSource" in this.props.userSettings &&
            (!this.props.isWLSite || this.props.wlFlags.menu_source) &&
            this.props.userSettings.logSource !== "" ? (
              <div
                style={{
                  marginLeft: documentTitle === "BRANDON BOOKS" ? "25px" : "10px",
                  fontSize: "14px",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
                onClick={this.props.handleOpenSettingsModal}
              >
                Source: <span style={{ fontWeight: "bold" }}>{this.props.userSettings.logSource}</span>
              </div>
            ) : (
              // <div style={{ marginLeft: "10px", fontSize: "14px", color: "#EA3323", fontWeight: "bold" }}>
              //   [[No Scan Source Selected]]
              // </div>
              <div></div>
            )}
          </div>

          <div style={{ clear: "both" }}></div>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {domainName !== "app.emptyshelves.com" && domainName !== "app.bookteriors.com" && (
              <Nav.Item>
                {this.props.isPrinterServerConnected === true ? (
                  <Nav.Link href="#home">
                    <div style={{ marginRight: "10px" }}>
                      <Form.Check
                        onChange={this.props.handleAutoPrint}
                        style={{ color: "#fff" }}
                        type="switch"
                        id="custom-switch-auto-print"
                        label="Auto Print"
                      />
                    </div>
                  </Nav.Link>
                ) : (
                  <span className="dot-disconnected"></span>
                )}
              </Nav.Item>
            )}

            {this.props.isMM !== null &&
              documentTitle !== "BLUEINKBOOKS" &&
              domainName !== "app.emptyshelves.com" &&
              domainName !== "app.bookteriors.com" && (
                <Nav.Item>
                  <Nav.Link href="#home">
                    <div style={{ marginRight: "10px" }}>
                      <Form.Check
                        onChange={this.props.handleMMToggle}
                        style={{ color: "#fff" }}
                        type="switch"
                        variant="success"
                        id="custom-switch-mm"
                        label="Marketplace"
                        defaultChecked={this.props.isMM === 1 ? true : false}
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
              )}

            {this.props.isMM === 1 && documentTitle === "EMPTY SHELVES" && (
              <Nav.Item>
                <Nav.Link onClick={this.props.handleOpenMPScans}>
                  <div style={{ marginRight: "10px" }}>
                    <i style={{ fontSize: "20px" }} className="fa fa-shopping-cart" />
                    <Badge style={{ position: "relative", top: "-13px" }} variant="light">
                      {this.props.cartCount > 0 ? this.props.cartCount : "..."}
                    </Badge>
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}

            {domainName !== "app.emptyshelves.com" && documentTitle !== "BOOKTERIORS" && (
              <Nav.Item>
                <Nav.Link href="#home">
                  <div style={{ marginRight: "10px" }}>
                    <Form.Check
                      onChange={this.props.handleLiveToggle}
                      style={{ color: "#fff" }}
                      type="switch"
                      id="custom-switch-live-load"
                      label="Live"
                    />
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}

            {/* <NavDropdown title="Listing Profile" id="collasible-nav-dropdown">
              {this.props.listingProfiles.map((lp) => (
                <NavDropdown.Item>
                  <div></div>
                  <Row>
                    <Col md={9} onClick={() => this.updateLP(lp.name)}>
                      {lp.name}
                    </Col>
                    <Col md={3}>
                      <i onClick={() => this.EditLP(lp.id)} className="fa fa-pencil lp-actions" />
                      <i onClick={() => this.EditLP(lp.id)} className="fa fa-close lp-actions" />
                    </Col>
                  </Row>
                </NavDropdown.Item>
              ))}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => this.setState({ showNewLPModal: true })}>Create New</NavDropdown.Item>
            </NavDropdown> */}

            {(!this.props.isWLSite || this.props.wlFlags.menu_sounds) && (
              <Nav.Item>
                <Nav.Link href="#home">
                  <div style={{ marginRight: "10px" }}>
                    <Form.Check
                      onChange={this.props.handleSoundToggle}
                      style={{ color: "#fff" }}
                      type="switch"
                      defaultChecked={this.props.shouldPlaySound}
                      id="custom-switch-sound-toggle"
                      label="Sound"
                    />
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}

            {documentTitle !== "BOOKTERIORS" &&
              domainName !== "app.emptyshelves.com" &&
              (!this.props.isWLSite || this.props.wlFlags.menu_shipments) && (
                <Nav.Item>
                  <Nav.Link onClick={this.props.handleOpenShipmentMap}>Shipments</Nav.Link>
                </Nav.Item>
              )}

            {domainName !== "app.emptyshelves.com" &&
              documentTitle !== "BOOKTERIORS" &&
              (!this.props.isWLSite || this.props.wlFlags.menu_scans) && (
                <Nav.Item>
                  <Nav.Link onClick={this.props.handleOpenPreviousScans}>Scans</Nav.Link>
                </Nav.Item>
              )}

            {/* <NavDropdown title="Printer Driver" id="download-bct">
              <NavDropdown.Item href="bct.pkg">Mac OS</NavDropdown.Item>
              <NavDropdown.Item href="bct.exe">Windows</NavDropdown.Item>
              <NavDropdown.Item href="bct.run">Linux</NavDropdown.Item>
            </NavDropdown> */}

            {domainName !== "app.emptyshelves.com" &&
              documentTitle !== "BOOKTERIORS" &&
              (!this.props.isWLSite || this.props.wlFlags.menu_settings) && (
                <Nav.Item>
                  <Nav.Link onClick={this.props.handleOpenSettingsModal}>Settings</Nav.Link>
                </Nav.Item>
              )}
            {this.props.isMultiProfiles === true && (
              <Nav.Item>
                <Nav.Link onClick={this.props.handleOpenProfileSwitchModal}>Switch profile</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopMenu;
