import { apiBase } from "../Config";

export const submitLogin = async (wl_team_id, user_email, user_password) => {
  const response = await fetch(apiBase + "user/login", {
    method: "POST",
    body: new URLSearchParams({
      wl_team_id: wl_team_id,
      user_email: user_email,
      user_password: user_password,
    }),
  });
  const data = await response.json();
  return data;
};

export const submitForgotPassword = async (user_email, wlTeamId) => {
  const response = await fetch(apiBase + "user/forgot-password", {
    method: "POST",
    body: new URLSearchParams({
      user_email: user_email,
      wl_team_id: wlTeamId,
    }),
  });
  const data = await response.json();
  return data;
};

export const submitResetPassword = async (app_token, new_password) => {
  const response = await fetch(apiBase + "user/reset-password", {
    method: "POST",
    body: new URLSearchParams({
      app_token: app_token,
      new_password: new_password,
    }),
  });
  const data = await response.json();
  return data;
};

// export const validateP = async (user_email) => {
//   const response = await fetch(apiBase + "user/forgot-password", {
//     method: "POST",
//     body: new URLSearchParams({
//       user_email: user_email,
//     }),
//   });
//   const data = await response.json();
//   return data;
// };
