import React, { Component } from 'react';
import { Modal, Form, Table, Spinner, Button, Row, Col } from 'react-bootstrap';

class ModalReconnectCredentials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // updateNotes: [],
    };
    // this.handleOpen = this.handleOpen.bind(this);
  }

  render() {
    return (
      <Modal
        style={{ zIndex: 999999 }}
        backdrop="static"
        keyboard={false}
        // onEntered={this.handleOpen}
        show={this.props.showReconnectModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>Pardon the interruption</h3>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '13.5px' }}>
            We've updated our software to work with the most recent Amazon APIs. To comply with the new regulations,
            we'll need you to re-connect your Amazon MWS with us. This is a one-time process, and the changes take
            effect instantaneously. The new update will also be able to identify restricted items for your Amazon Seller
            Central.
          </div>

          <Row
            style={{
              fontSize: '14px',
              marginTop: '5px',
              alignItems: 'center',
              borderTop: '1px solid #ddd',
            }}
          >
            <Col md={6}>
              <Form onSubmit={this.props.updateAmazonCredentials}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>Amazon Seller ID</td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Form.Control required type="text" name="mwsSellerId" className="newGoals_input_center" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>Amazon Auth Token</td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Form.Control required type="text" name="mwsAuthToken" className="newGoals_input_center" />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <div style={{ marginTop: '20px' }}>
                  {this.props.isUpdatingAmazonCredentials === true ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <Button variant="primary" type="submit">
                      Save and Proceed
                    </Button>
                  )}
                </div>
              </Form>
            </Col>

            <Col md={6} style={{ borderLeft: '1px dotted #ddd', paddingTop: '12px' }}>
              <h5>Step by Step Guide</h5>

              <div style={{ marginTop: '15px' }}>
                <h6>Step 1</h6>
                <p style={{ fontSize: '13px' }}>
                  Copy this information: <br />
                  <br />
                  Name: <b>ScoutIQ</b>
                  <br />
                  Developer ID: <b>537592297183</b>
                </p>
              </div>

              <div style={{ marginTop: '15px' }}>
                <h6>Step 2</h6>
                <p style={{ fontSize: '13px' }}>
                  Click the button below and enter the above information on that page. Click "Next" and accept the terms
                  and conditions.
                </p>

                <div style={{ marginTop: '15px' }}>
                  <Button
                    href="https://sellercentral.amazon.com/gp/mws/registration/register.html/133-1576208-6497101?devAuth=1&devMWSAccountId=5375-9229-7183&developerName=ScoutIQ&ie=UTF8&signInPageDisplayed=1"
                    variant="dark"
                    type="button"
                  >
                    Get MWS Keys
                  </Button>
                </div>
              </div>

              <div style={{ marginTop: '15px' }}>
                <h6>Step 3</h6>
                <p style={{ fontSize: '13px' }}>
                  Copy your Seller ID and your Amazon Auth Token and paste on this page.
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalReconnectCredentials;
