import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";

class Step1Binary extends Component {
  state = {
    errorMessage: "",
  };

  constructor(props) {
    super(props);
    this.pressKeyFunction = this.pressKeyFunction.bind(this);
  }

  pressKeyFunction(event) {
    if (event.keyCode === 89 || event.keyCode === 121) {
      this.props.exportBinary(true);
      this.setState({ errorMessage: "" });
      this.props.gotoNextScreen();
    }
    if (event.keyCode === 78 || event.keyCode === 110) {
      this.props.exportBinary(false);
      this.setState({ errorMessage: "" });
      this.props.gotoNextScreen();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.pressKeyFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.pressKeyFunction, false);
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>{this.props.heading}</h2>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p style={{ fontSize: "18px" }}>{this.props.message}</p>
          {this.props.smallMessage !== "" && (
            <div style={{ marginTop: "10px" }}>
              <small>{this.props.smallMessage}</small>
            </div>
          )}

          <Form.Check
            inline
            label="YES (Press Y)"
            type="radio"
            name="is-binary"
            id="is-bin-yes"
            checked={this.props.inputVal === true}
            onChange={(e) => {
              this.props.exportBinary(true);
            }}
          />
          <Form.Check
            style={{ marginLeft: "25px" }}
            inline
            label="NO (Press N)"
            type="radio"
            name="is-binary"
            id="is-bin-no"
            checked={this.props.inputVal === false}
            onChange={(e) => {
              this.props.exportBinary(false);
            }}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ margin: "20px 20px 0 0" }}
              variant="primary"
              onClick={() => {
                this.setState({ errorMessage: "" });
                this.props.gotoPreviousScreen();
              }}
            >
              Previous
            </Button>
            <Button
              style={{ marginTop: "20px" }}
              variant="primary"
              onClick={() => {
                if (this.props.inputVal === "" || this.props.inputVal === null) {
                  this.setState({ errorMessage: "Input Required" });
                } else {
                  this.setState({ errorMessage: "" });
                  this.props.gotoNextScreen();
                }
              }}
            >
              Next
            </Button>
          </div>

          {this.state.errorMessage !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Step1Binary;
