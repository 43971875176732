import React, { Component } from 'react';
import { Row, Col, Form, Modal, Button, Spinner } from 'react-bootstrap';
import { apiBase, winnersMap } from '../Config';

class ModalSwitchProfile extends Component {
  state = {};
  constructor() {
    super();
  }

  render() {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        className="modal-user-settings"
        show={this.props.showProfileSwitchModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>Who is Scanning?</h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {this.props.profiles &&
              this.props.profiles.map((profile) => (
                <div
                  style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginRight: '20px',
                    marginBottom: '20px',
                    border: '1px solid #ddd',
                    padding: '10px',
                  }}
                  onClick={() => this.props.changeActiveProfile(profile.name)}
                >
                  <div>
                    <img
                      style={{ width: '100px' }}
                      src={`https://files.palletiq.com/profile_images/${profile.picture}`}
                    />
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: '500', marginTop: '5px' }}>{profile.name}</div>
                </div>
              ))}
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
            {this.props.uploadingSettings === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit" disabled={this.state.fetchingEnabledMods}>
                Save Changes
              </Button>
            )}
          </Modal.Footer> */}
      </Modal>
    );
  }
}

export default ModalSwitchProfile;
