import Cookies from "universal-cookie";
import { apiBase } from "./Config";

class Auth {
  constructor() {
    this.cookies = new Cookies();
  }

  login(response, cb) {
    this.cookies.set("user_data", response, { path: "/" });
    this.cookies.set("user_id", response.user_id, { path: "/" });
    this.cookies.set("token", response.token, { path: "/" });
    this.cookies.set("is_hidden", response.hidden_mode, { path: "/" });
    this.cookies.set("show_sales_rank", response.show_sales_rank, { path: "/" });
    cb();
  }

  logout(cb) {
    this.cookies.remove("user_data");
    this.cookies.remove("user_id");
    this.cookies.remove("token");
    this.cookies.remove("is_hidden");
    this.cookies.remove("show_sales_rank");
    cb();
  }

  getUserData() {
    return this.cookies.get("user_data");
  }
  setUserData(userData) {
    this.cookies.set("user_data", userData, { path: "/" });
  }

  async isAuthenticated() {
    const userData = this.cookies.get("user_data");

    if (undefined !== userData && "token" in userData) {
      try {
        console.log(`Validating Token`);
        const response = await fetch(`${apiBase}user/validate-token?id=${userData.token}`);
        const userDataJson = await response.json();

        if (response.status === 200) {
          this.setUserData(userDataJson);
          return true;
        } else {
          console.log(`Didn't match`);
          return false;
        }
      } catch (error) {
        return false;
      }
    } else return false;
  }
}

// Exporting singleton class
export default new Auth();
